import React, { useEffect, useState } from 'react'
import './product.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllProducts, getProducts, resetState } from '../../features/products/productSlice';
import { getACollection } from '../../features/collection/collectionSlice';
import { BiSortAlt2 } from "react-icons/bi";
import { GoFilter } from "react-icons/go";
import Product from '../../components/Product'
import { IoCloseOutline } from "react-icons/io5";
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import logo from '../../images/vlogo.png'
import loadingImg from '../../images/loading.gif'

const Products = () => {
  const [filterOpen, setFilterOpen] = useState("-100%")
  const [sortOpen, setSortOpen] = useState("-100%")
  const [size, setSize] = useState("")
  const [color, setColor] = useState("")
  const [brand, setBrand] = useState("")
  const [menu, setMenu] = useState("size")
  const colorMenu = ["Red", "Yellow", "Green", "Orange", "White", "Black", "Grey", "Blue", "Purple", "Pink", "Brown"]
  const brandMenu = [
    "Amiri",
    "Armani",
    "Balenciaga",
    "Bally",
    "Balmain",
    "Bottega Veneta",
    "Burberry",
    "Calvin Klein",
    "Celine",
    "Chanel",
    "Christian Louboutin",
    "Coach",
    "Dior",
    "DKNY",
    "Dolce & Gabbana",
    "Fendi",
    "Fred Perry",
    "Givenchy",
    "Gucci",
    "Hermes",
    "Hugo Boss",
    "Karl",
    "Kenzo",
    "Lanvin",
    "Louis Vuitton",
    "Michael Kors",
    "Moncler",
    "Moschino",
    "Off-White",
    "Prada",
    "Ralph Lauren",
    "Salvatore Ferragamo",
    "Stefano Ricci",
    "Tod's",
    "Tom Ford",
    "Tory Burch",
    "Valentino",
    "Versace",
    "Victoria's Secret",
    "Yves Saint Laurent (YSL)",
    "Zegna"
  ];

  const collectionState = useSelector((state) => state?.collection?.singleCollection)
  const collectionStat = useSelector((state) => state?.collection)
  const {isError}=collectionStat
  const navigate = useNavigate()
  const [collectionName, setCollectionName] = useState("")
  const [sort, setSort] = useState("-createdAt")
  const location = useLocation()
  const limit = 16
  const [loading, setLoading] = useState(true)
  const [searchValue, setSearchvalue] = useState(undefined)
  const [err,setErr]=useState(false)

  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  let page = parseInt(queryParams.get('page')) || 1;
  const search = JSON.parse(localStorage.getItem("search"))
  useEffect(() => {
    const search = JSON.parse(localStorage.getItem('search'));
    if (search && location.pathname === '/products') {
      setSearchvalue(search.mysearch);
      // Update the URL with search.mysearch
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('search', search.mysearch);
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  }, [search && search.mysearch]);

  const pathname = location.pathname; // Gives "/collections/men-premium-shirt"
  const segments = pathname.split('/'); // Splits the pathname into segments
  const collection = segments[2]; // Gets "men-premium-shirt" assuming it's always in this position
  useEffect(() => {
    window.scrollTo({
      top: -10,
    });
  })

  useEffect(() => {
    if (location?.pathname !== "/products") {
      localStorage.removeItem("search")

    }

  }, [location])

  useEffect(() => {

  })
  useEffect(() => {
    dispatch(resetState())
  }, [dispatch])
  const updateURL = (sizeNumber) => {
    const searchParams = new URLSearchParams();

    // Add page parameter
    searchParams.set('page', sizeNumber);

    // Add filter parameters
    if (size !== "") {
      searchParams.set('size', size);
    }
    if (color !== "") {
      searchParams.set('color', color);
    }
    if (brand !== "") {
      searchParams.set('brand', brand);
    }

    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const productState = useSelector((state) => state?.product?.product)
  const productState1 = useSelector((state) => state?.product?.prdt)
  const productStat = useSelector((state) => state?.product);

  const { isLoading, isSuccess } = productStat


  useEffect(() => {
    const filter = {};

    // Add selected sizes to the filter object
    if (size !== "") {
      filter.size = size;
    }

    // Add selected colors to the filter object
    if (color !== "") {
      filter.color = color;
    }

    // Add selected brands to the filter object
    if (brand !== "") {
      filter.brand = brand;
    }
    if (searchValue === undefined) {
      dispatch(getAllProducts({ sort, limit, page, collectionName: collectionName, ...filter }))
    }
    else {
      dispatch(getProducts({ searchValue, limit, sort, page, ...filter }))
    }
  }, [searchValue, page, collectionName, sort])
  useEffect(() => {
    if (collection) {
      setLoading(true);
      dispatch(getACollection(collection))
        .unwrap()
        .then((response) => {
          setCollectionName(response.title);
        })
        .catch((error) => {
          console.error('Error fetching collection:', error);
          setLoading(false);
        });
    }
  }, [dispatch, collection]);
  const products = searchValue === undefined ? (productState ? productState : []) : (productState1 ? productState1 : [])

  useEffect(() => {
    if (isLoading && (productState || productState1)) {
      setLoading(true)
    }
    if (isSuccess && (productState || productState1)) {
      setTimeout(() => {
        setLoading(false)
      }, 1500)
    }
  }, [isLoading, isSuccess, productState, productState1])

  useEffect(() => {
    setLoading(true)
  }, [])

  const uniqueSizes = new Set();

  products?.products?.forEach(product => {
    product.variants.forEach(variant => {
      uniqueSizes.add(variant.size);
    });
  });

  // Convert the Set to an array
  const uniqueSizesArray = Array.from(uniqueSizes)

  const toggleSize = (sizes) => {
    if (size === sizes) {
      setSize("")
    }
    else {
      setSize(sizes)
    }
  }
  const toggleColor = (colors) => {
    if (color === colors) {
      setColor("")
    }
    else {
      setColor(colors)
    }
  }
  const toggleBrand = (brands) => {
    if (brand === brands) {
      setBrand("")
    }
    else {
      setBrand(brands)

    }
  }
  const toggleMenu = (menu) => {
    setMenu(menu)
  }
  const toggleSort = (sort) => {
    setSort(sort)
    setLoading(true)
    setSortOpen("-100%")
  }

  const isSelectedSize = (sizes) => size === sizes ? 'selected' : '';
  const isSelectedColor = (colors) => color === colors ? 'selected' : '';
  const iseSelectedBrand = (brands) => brand === brands ? 'selected' : '';
  const iseSelectedMenu = (menus) => menu === menus ? 'selected' : '';
  const isSelectedSort = (sorts) => sort === sorts ? 'selected' : '';

  const openFilter = () => {
    setFilterOpen("0")
  }
  const closeFilters = () => {
    setFilterOpen("-100%")
  }
  const openSorting = () => {
    setSortOpen("0")
  }
  const closeSorting = () => {
    setSortOpen("-100%")
  }
  const applyFilter = () => {
    const filter = {};
    setLoading(true)
    setFilterOpen("-100%")

    // Add selected sizes to the filter object
    if (size !== "") {
      filter.size = size;
    }

    // Add selected colors to the filter object
    if (color !== "") {
      filter.color = color;
    }

    // Add selected brands to the filter object
    if (brand !== "") {
      filter.brand = brand;
    }
    const searchParams = new URLSearchParams(location.search);
    if (filter.size) {
      searchParams.set('size', filter.size);
    } else {
      searchParams.delete('size');
    }
    if (filter.color) {
      searchParams.set('color', filter.color);
    } else {
      searchParams.delete('color');
    }
    if (filter.brand) {
      searchParams.set('brand', filter.brand);
    } else {
      searchParams.delete('brand');
    }

    navigate(`${location.pathname}?${searchParams.toString()}`);

    if (searchValue === undefined) {
      dispatch(getAllProducts({ sort, limit, page, collectionName, ...filter }));
    }
    if (searchValue !== undefined) {
      dispatch(getProducts({ searchValue, limit, sort, page, ...filter }))

    }
  };

  const clearFilter = () => {
    setLoading(true)
    setSize("")
    setColor("")
    setBrand("")
    if (searchValue === undefined) {
      dispatch(getAllProducts({ sort: sort, limit, page, collectionName }));

    }
    else {
      dispatch(getProducts({ searchValue, limit, sort, page }))

    }
    navigate(location.pathname);  // Clears all query parameters


    setFilterOpen("-100%")
  }
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sizesParam = queryParams.get('size');
    const colorsParam = queryParams.get('color');
    const brandsParam = queryParams.get('brand');

    if (sizesParam) {
      setSize(sizesParam);
    } else {
      setSize("");
    }

    if (colorsParam) {
      setColor(colorsParam);
    } else {
      setColor("");
    }

    if (brandsParam) {
      setBrand(brandsParam);
    } else {
      setBrand("");
    }
  }, [location.search]);


  useEffect(() => {
      const canonicalUrl =`https://voguemine.com/collections/${collection}`;
      let canonicalLink = document.querySelector('link[rel="canonical"]');

      if (canonicalLink) {
        canonicalLink.setAttribute('href', canonicalUrl);
      } else {
        canonicalLink = document.createElement('link');
        canonicalLink.setAttribute('rel', 'canonical');
        canonicalLink.setAttribute('href', canonicalUrl);
        document.head.appendChild(canonicalLink);
      }
  }, [collection]);
  useEffect(() => {
    if (collectionState?.metaDesc !== "") {
      document.querySelector('meta[name="description"]').setAttribute('content', collectionState?.metaDesc);
    }
    if(collectionState?.metaDesc === "") {
      document.querySelector('meta[name="description"]').setAttribute('content', document.createElement('div').innerHTML = collectionState?.title);
    }

  }, [collectionState?.metaDesc]);


  useEffect(() => {
    if (collectionState?.metaTitle !== "") {
      document.title = collectionState?.metaTitle;
    }
    if(collectionState?.metaTitle === "") {
      document.title = collectionState?.title;
    }

  }, [collectionState?.title]);

  const handlePageChange = (event, value) => {
    updateURL(value); // Update the URL with the new page value
    setLoading(true)
  };

  return (
    <>
<div className='Products'>
      <div className="loading" style={{ display: loading === true ? "block" : "none" }}>
        <div className="loader">
          <img src={loadingImg} alt="" />
          <p>Loading . . .</p>
        </div>
      </div>
      <h1 className="p-headi">{(searchValue && searchValue) || (collectionState && collectionState?.category)}</h1>
      <div className="products-box">

        <div className="products-page">
          <div className="filter">
            <p className="option" onClick={openFilter}>
              <GoFilter />
              <span>Filter</span>
            </p>
            <p className="option" onClick={openSorting}>
              <BiSortAlt2 />
              <span>Sort</span>
            </p>
            <div className="filter-box" style={{ bottom: filterOpen }}>
              <div className="headers">
                <p className="heads">FILTER</p>
                <span onClick={closeFilters}><IoCloseOutline /></span>
              </div>
              <div className="filter-body">
                <div className="left">
                  <ul>
                    <li onClick={() => toggleMenu("color")} className={iseSelectedMenu("color")}>Color</li>
                    <li onClick={() => toggleMenu("size")} className={iseSelectedMenu("size")}>Size</li>
                    <li onClick={() => toggleMenu("brand")} className={iseSelectedMenu("brand")}>Brand</li>
                  </ul>
                </div>
                <div className="right">
                  {
                    menu === "size" ?
                      <div className="content">
                        <p>Size</p>
                        <ul>
                          {uniqueSizesArray.map((size, index) => (
                            <li key={index} onClick={() => toggleSize(size)} className={isSelectedSize(size)}>{size}</li>
                          ))}
                        </ul>
                      </div>
                      :
                      menu === "color" ?
                        <div className="content">
                          <p>Color</p>
                          <ul>
                            {colorMenu.map((color, index) => (
                              <li key={index} onClick={() => toggleColor(color)} className={isSelectedColor(color)}>{color}</li>
                            ))}
                          </ul>
                        </div>
                        :
                        <div className="content">
                          <p>Brand</p>
                          <ul>
                            {brandMenu.map((brand, index) => (
                              <li key={index} onClick={() => toggleBrand(brand)} className={iseSelectedBrand(brand)}>{brand}</li>
                            ))}
                          </ul>
                        </div>
                  }

                </div>
              </div>
              <div className="filter-foot">
                <p className="count">
                  28 Products
                </p>
                <div className="right">
                  <button onClick={clearFilter}>Clear Filters</button>
                  <button onClick={applyFilter}>Done</button>
                </div>
              </div>
            </div>
            <div className="sort-box" style={{ bottom: sortOpen }}>
              <div className="headers">
                <p className="heads">SORT</p>
                <span onClick={closeSorting}><IoCloseOutline /></span>
              </div>
              <div className="sort-body">
                <p onClick={() => toggleSort("-createdAt")} className={isSelectedSort("-createdAt")}>New to Old</p>
                <p onClick={() => toggleSort("createdAt")} className={isSelectedSort("createdAt")}>Old to New</p>
                <p onClick={() => toggleSort("price")} className={isSelectedSort("price")}>Price (Low to High)</p>
                <p onClick={() => toggleSort("-price")} className={isSelectedSort("-price")}>Price (High to Low)</p>
                <p onClick={() => toggleSort("title")} className={isSelectedSort("title")}>Title (A to Z)</p>
                <p onClick={() => toggleSort("-title")} className={isSelectedSort("-title")}>Title (Z to A)</p>
              </div>
            </div>
          </div>
          <div className="products-listing">
            {
              loading ? <div className="skeleton">
                <img src={logo} alt="" key={"vogue-img"} />
              </div>
                :
                <div className="product-list">
                  {
                    products?.products?.length <= 0 ? setLoading(true) :
                      products?.products?.map((arm, index) => {
                        return <Product key={index} id={arm?._id} img={arm?.images} title={arm?.title} price={arm?.price} variants={arm?.variants} handle={arm?.handle} prdt={arm} alt={arm?.alt} />
                      })
                  }
                </div>
            }
          </div>
          <div className="pages">
            {
              loading === true ? <CircularProgress /> :
                <Stack spacing={2}>
                  <Pagination count={products?.pagination?.totalPages} page={page} onChange={handlePageChange} /></Stack>}
          </div></div></div></div>
    </>
    )
}

export default Products
